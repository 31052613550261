const theme = {
  colors: {
    // primary palette
    darkBlue: "#021859",
    skyBlue: "#0B9ED9",
    deepPink: "#FF4597",
    deepOrange: "#f90",
    // secondary palette
    mediumBlue: "#0433BF",
    purple: "#B850FF",
    mediumVioletRed: "#BB3F75",
    mediumOrangeYellow: "#fc7502",
    yellow: "#FFC700",
    lightGreen: "#54D6A6",
    crimson: "#E81E61",
    // gray palette
    dimGray: "#616670",
    gray: "#ACB5C6",
    silver: "#C3CDE0",
    lightGray: "#E6E8F0",
    paleGray: "#F7F7FA",
    white: "#FFFFFF",
    vasslaBlack: "#0D0F0F"
  },
  breakpoints: {
    xs: "(min-width: 375px)",
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)",
    xxl: "(min-width: 1440px)"
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 600,
    bold: 600,
    bolder: 900
  }
}

export default theme
