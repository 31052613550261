import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    color: #242e42;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  body {
    position: relative;
    overflow-x: hidden;
    margin: 0;
    background-color: #f7f7fa;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  a {
    color: #021859;
    text-decoration: none;
    background-color: transparent;

    &:hover {
      color: #fc7502;
      text-decoration: none;
      outline: 0;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: 0;
    }
  }

  p a:link,
  p a:visited {
    border-bottom: 2px solid #6af;
    color: #444;
    padding-bottom: 1px;
    text-decoration: none;
    transition: 0.25s;
  }

  p a:hover,
  p a:focus {
    color: #fc7502;
  }

  p a:active {
    position: relative;
    top: 1px;
    transition: none;
  }

  img {
    border: 0;
    max-width: 100%;
    vertical-align: top;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
  }

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export default GlobalStyle
