import OneSignal from "react-onesignal"
import { debug } from "scripts/common"
import noop from "lodash/noop"

export function init() {
  if (debug) return

  const options = {
    safari_web_id: process.env.NEXT_PUBLIC_ONE_SIGNAL_SAFARI_ID,
    autoRegister: false,
    autoResubscribe: true,
    notifyButton: {
      enable: false
    }
  }

  setTimeout(() => {
    OneSignal.initialize(process.env.NEXT_PUBLIC_ONE_SIGNAL_ID, options)
  }, 4000)
}

export function trackConversion(email) {
  if (debug) return

  OneSignal.sendTag("converted", true).then(noop).catch(noop)
  if (email) OneSignal.setEmail(email).then(noop).catch(noop)
}

export function promptNotifications() {
  if (debug) return

  OneSignal.registerForPushNotifications().then(noop).catch(noop)
}
