export const ALLOWED_LOCALES = ["es", "pt", "it"]

export const MARKETING_FIELDS = [
  "gclid",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_adgroup",
  "utm_content",
  "utm_term",
  "utm_retarget_campaign",
  "adposition",
  "device",
  "loc_physical_ms",
  "matchtype",
  "adid",
  "origin",
  "campaignid",
  "adgroupid",
  "keyword",
  "matchtype",
  "creativeid",
  "targetid",
  "network",
  "loc_physical_ms",
  "placement",
  "originalUrl"
]

export const PHONE_PREFIX = {
  ES: "+34",
  PT: "+351",
  IT: "+39"
}

export const mainVideoId = {
  es: "yHkLwiiShDo",
  it: "NiD5pHK6ado"
}

export const BRANDS = [
  "seat",
  "toyota",
  "volkswagen",
  "fiat",
  "audi",
  "peugeot",
  "ford",
  "citroen",
  "nissan",
  "bmw",
  "opel",
  "hyundai",
  "mercedes",
  "cupra",
  "jeep",
  "kia",
  "renault",
  "dacia",
  "ds",
  "honda",
  "jaguar",
  "land-rover",
  "lexus",
  "maserati",
  "mazda",
  "mini",
  "mitsubishi",
  "alfa-romeo",
  "porsche",
  "skoda",
  "smart",
  "suzuki",
  "tesla",
  "volvo",
  "ssang-yong",
  "subaru",
  "mg"
]

export const MULTISTEP_BRANDS = ["seat", "peugeot", "toyota", "volkswagen", "audi", "nissan", "bmw", "opel", "hyundai"]

export const FCA_PROVINCE_OPTIONS = [
  { value: "EE", label: "Paises Extranjeros" },
  { value: "VI", label: "Álava" },
  { value: "AB", label: "Albacete" },
  { value: "A", label: "Alicante" },
  { value: "AL", label: "Almería" },
  { value: "AV", label: "Ávila" },
  { value: "BA", label: "Badajoz" },
  { value: "IB", label: "Baleares" },
  { value: "B", label: "Barcelona" },
  { value: "BU", label: "Burgos" },
  { value: "CC", label: "Cáceres" },
  { value: "CA", label: "Cadiz" },
  { value: "CS", label: "Castellón" },
  { value: "CE", label: "Ceuta" },
  { value: "CR", label: "Ciudad Real" },
  { value: "CO", label: "Córdoba" },
  { value: "CU", label: "Cuenca" },
  { value: "GI", label: "Gerona" },
  { value: "GR", label: "Granada" },
  { value: "GU", label: "Guadalajara" },
  { value: "SS", label: "Guipúzcoa" },
  { value: "H", label: "Huelva" },
  { value: "HU", label: "Huesca" },
  { value: "J", label: "Jaén" },
  { value: "C", label: "La Coruña" },
  { value: "LO", label: "La Rioja" },
  { value: "GC", label: "Las Palmas" },
  { value: "LE", label: "León" },
  { value: "L", label: "Lérida" },
  { value: "LU", label: "Lugo" },
  { value: "M", label: "Madrid" },
  { value: "MA", label: "Málaga" },
  { value: "ML", label: "Melilla" },
  { value: "MU", label: "Murcia" },
  { value: "NA", label: "Navarra" },
  { value: "OR", label: "Orense" },
  { value: "O", label: "Oviedo" },
  { value: "P", label: "Palencia" },
  { value: "PO", label: "Pontevedra" },
  { value: "SH", label: "Sáhara Occidental" },
  { value: "SA", label: "Salamanca" },
  { value: "S", label: "Santander" },
  { value: "SG", label: "Segovia" },
  { value: "SE", label: "Sevilla" },
  { value: "SO", label: "Soria" },
  { value: "TF", label: "Sta.Cruz de Tenerife" },
  { value: "T", label: "Tarragona" },
  { value: "TE", label: "Teruel" },
  { value: "TO", label: "Toledo" },
  { value: "V", label: "Valencia" },
  { value: "VA", label: "Valladolid" },
  { value: "BI", label: "Vizcaya" },
  { value: "ZA", label: "Zamora" },
  { value: "Z", label: "Zaragoza" }
]

export const SUBROGATION_FINANCE_AGENCIES = [
  { value: "Leaseplan", label: "Leaseplan" },
  { value: "FCA/Leasys", label: "FCA/Leasys" },
  { value: "Banco Sabadell", label: "Banco Sabadell" },
  { value: "ALD", label: "ALD" },
  { value: "Alphabet", label: "Alphabet" },
  { value: "Otra", label: "Otra" }
]

export const brandsWithPicture = {
  "alfa-romeo": [],
  audi: ["a1", "a3", "a4", "a5", "a6", "q2", "q3", "q5", "q7", "q8"],
  bmw: ["serie-1", "serie-2", "serie-3", "serie-4", "serie-5", "x1", "x2", "x3", "x4", "x5", "x6", "i3"],
  citroen: ["c1", "c4", "berlingo", "c3-aircross", "jumper"],
  cupra: [],
  dacia: [],
  ds: [],
  fiat: ["doblo", "doblo-cargo", "ducato", "500x", "500", "fiorino", "talento", "tipo"],
  ford: [
    "fiesta",
    "focus",
    "mondeo",
    "puma",
    "transit-custom",
    "transit-chasis",
    "transit-connect",
    "transit-courier",
    "transit",
    "ecosport",
    "kuga",
    "s-max",
    "tourneo-connect"
  ],
  honda: [],
  hyundai: [],
  jaguar: [],
  jeep: ["compass", "renegade"],
  kia: ["niro", "sportage", "stonic"],
  "land-rover": ["evoque", "velar"],
  lexus: [],
  maserati: [],
  mazda: [],
  mercedes: [],
  mini: [],
  mitsubishi: [],
  nissan: ["e-nv200", "juke", "leaf", "micra", "nt400", "qashqai", "x-trail"],
  opel: ["corsa", "combo-cargo"],
  peugeot: ["208", "2008", "308", "3008", "508", "5008", "partner", "rifter"],
  porsche: [],
  renault: [],
  seat: ["alhambra", "arona", "ateca", "ibiza", "leon", "tarraco", "mii"],
  skoda: [],
  smart: [],
  ssangyong: [],
  subaru: [],
  suzuki: [],
  tesla: [],
  toyota: ["c-hr", "corolla", "hilux", "proace-city", "rav4", "yaris-hybrid", "yaris", "prius", "verso"],
  volkswagen: [
    "e-golf",
    "golf-gte",
    "golf",
    "t-cross",
    "t-roc",
    "arteon",
    "caddy",
    "passat",
    "polo",
    "tiguan-allspace",
    "tiguan",
    "touareg"
  ],
  volvo: []
}

export const CAR_COLORS = {
  white: "#ffffff",
  black: "#000000",
  red: "#ff0000",
  blue: "#0000ff",
  green: "#008000",
  grey: "#808080",
  pink: "#ffc0cb",
  purple: "#800080",
  silver: "#c0c0c0",
  orange: "#ffa500",
  brown: "#896b49",
  bronze: "#cd7f32",
  yellow: "#ffc700"
}
