import { isServer, debug } from "scripts/common"

export function trackTransaction(lead, carId, email) {
  if (isServer) return
  const items = []
  items.push({ id: carId || "formulario", price: 1, quantity: 1 })
  window.criteo_q.push(...defaultParams(email), {
    event: "trackTransaction",
    id: lead.id,
    item: items
  })
}

export function trackBasket(carId) {
  if (isServer) return
  const items = []
  items.push({ id: carId || "formulario", price: 1, quantity: 1 })
  window.criteo_q.push(...defaultParams(), {
    event: "viewBasket",
    item: items
  })
}

export function trackViewList(cars) {
  if (isServer) return
  const ctots = window.firstPath ? Date.now().toString().substring(0, 10) : ""
  window.criteo_q.push(...defaultParams(), { event: "viewList", item: cars.map(c => c.id), ctots })
}

export function trackViewHome() {
  if (isServer) return
  const ctots = window.firstPath ? Date.now().toString().substring(0, 10) : ""
  window.criteo_q.push(...defaultParams(), { event: "viewHome", ctots })
}

export function trackViewItem(id) {
  if (isServer || !id) return
  const ctots = window.firstPath ? Date.now().toString().substring(0, 10) : ""
  window.criteo_q.push(...defaultParams(), { event: "viewItem", item: id, ctots })
}

function defaultParams(email) {
  return [
    { event: "setAccount", account: getAccount() },
    { event: "setEmail", email: email || "" },
    { event: "setSiteType", type: window.deviceType }
  ]
}

const getAccount = () => {
  switch (window.countryCode) {
    case "ES":
      return 60934
    case "PT":
      return 68793
    case "IT":
      return 74348
    default:
      return null
  }
}

export function init(countryCode = "ES") {
  window.criteo_q = window.criteo_q || []
  if (debug) return

  setTimeout(() => {
    if (["ES", "PT", "IT"].includes(countryCode)) {
      const head = document.getElementsByTagName("head")[0]
      const js = document.createElement("script")
      js.src = "//static.criteo.net/js/ld/ld.js"
      js.async = true
      head.appendChild(js)
    }
    // eslint-disable-next-line
    window.deviceType = /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? "m"
      : "d"
  }, 3000)
}
