import "scripts/wdyr"
import * as PropTypes from "prop-types"
import { useMemo } from "react"
import { DefaultSeo } from "next-seo"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "components/styled/GlobalStyles"
import initAxios from "lib/withAxios"
import GlobalSeoData from "components/GlobalSeoData"
import initializers from "Initializers"
import { useOneSignalSetup } from "react-onesignal"
import { promptNotifications } from "scripts/OneSignal"
import theme from "theme"
import wrapper from "../src/redux"
import SEO from "../next-seo.config"
import "../src/index.css"

initAxios()

function MyApp(props) {
  const { Component, pageProps, err } = props

  useMemo(initializers, [])
  useOneSignalSetup(promptNotifications)

  return (
    <>
      <DefaultSeo {...SEO} />
      <GlobalSeoData />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </>
  )
}

MyApp.defaultProps = {
  err: null
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object
}

export default wrapper.withRedux(MyApp)
