import { LogoJsonLd, SocialProfileJsonLd } from "next-seo"

export default function GlobalSeoData() {
  return (
    <>
      <SocialProfileJsonLd
        type="Organization"
        name="Renting Finders"
        url="https://swipcar.com"
        sameAs={[
          "https://www.facebook.com/Renting Finders-1941213906123935",
          "https://www.instagram.com/swipcar",
          "https://www.linkedin.com/company/11181106",
          "https://www.youtube.com/channel/UCKdvqfjYM53rrNh9RWKtq_Q",
          "https://twitter.com/swipcar_renting"
        ]}
      />
      <LogoJsonLd
        logo="https://res.cloudinary.com/dag3rmkxg/image/upload/v1592477744/static/logos/swipcar-purple-logo.svg"
        url="https://swipcar.com"
      />
    </>
  )
}
