import { Router } from "next/router"
import { parse } from "query-string"
import { init as initGTM } from "scripts/GoogleTagManager"
// import { init as initHotjar } from "scripts/Hotjar"
import { init as initCriteo } from "scripts/Criteo"
import { init as initSentry } from "scripts/Sentry"
// import { init as initOneSignal } from "scripts/OneSignal"
// import { init as initFB, sendPageView as sendFBPageView } from "scripts/FacebookPixel"
import { sendPageView as sendGAPageView } from "scripts/GoogleAnalytics"
// import { init as initBing, sendPageView as sendBingPageView } from "scripts/BingAds"
// import { init as initTVSquared, sendPageView as sendTVSquaredPageView } from "scripts/TVSquared"
// import { init as initSpoteffects, sendPageView as sendSpoteffectsPageView } from "scripts/Spoteffects"
import { MARKETING_FIELDS } from "helpers/constants"
import { createCookie } from "helpers/cookies"
import { init as initClientFingerprint } from "lib/fingerprint"

const isServer = typeof window === "undefined"
let lastPath = !isServer && window.location.pathname
if (!isServer) window.firstPath = true

export default function initializers() {
  initSentry()

  if (isServer) return
  const { pathname } = window.location
  const countryCode = window.countryCode || pathname.split("/")[1].toUpperCase()

  console.debug("Initialize")

  // initHotjar()
  // initOneSignal()
  initGTM(countryCode, pathname)
  // initFB(countryCode)
  // initBing()
  initCriteo(countryCode)
  initClientFingerprint()
  // initTVSquared()
  // initSpoteffects()

  Router.events.on("routeChangeComplete", url => {
    window.firstPath = false
    const path = url.split("?")[0]
    if (lastPath === path) return
    lastPath = path
    sendGAPageView(path)
    // sendBingPageView(path)
    // sendFBPageView()
    // sendSpoteffectsPageView(path)
    // sendTVSquaredPageView(path)
  })
  createCookie('originalUrl', window.location.href)
  const { keyword, ...rest } = parse(window.location.search)
  MARKETING_FIELDS.forEach(field => {
    createCookie(field, rest[field])
  })
}
