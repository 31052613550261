import axios from "axios"
import pickBy from "lodash/pickBy"
import * as crypto from "crypto";
import { captureException } from "@sentry/nextjs"

const apiUrl = process.env.NEXT_PUBLIC_API_URL

const headers = {
  Authorization: `bearer ${process.env.NEXT_API_TOKEN}`
}

export function get(id) {
  return fetch(`${apiUrl}leads/${id}`, { headers })
}

export function newOpportunity(params, car = {}, path_params = {}) {
  const token = { tok: bearerToken() };
  const result = pickBy(params, v => v !== undefined && v !== null);
  const {id, brand, model, version, slug } = car;
  return axios.post(
      `https://admin.rentingfinders.com/receptor_json_swipcar.php`,
      JSON.stringify({ lead: { ...result, ...token, ...{ vehicle: { id, brand, model, version, slug }, path_params } } }),
  )
}

export function update(id, params) {
  return axios.put(
    `${apiUrl}leads/${id}`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function sendFingerprint(code) {
  if (!code) return null
  return axios.get(`${apiUrl}leads/code?code=${code}`).catch(err => captureException("axios rejected", { extra: err }))
}

export function createSubscriber(params, multistep) {
  const endpoint = multistep ? "create_subscriber" : "subscribers"
  return axios.post(
    `${apiUrl}${endpoint}`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function getMultistepResults(params) {
  return axios.get(`${apiUrl}multistep/results`, {
    params: { ...pickBy(params, v => v !== undefined && v !== null), ...{ country_code: window.countryCode } }
  })
}

export function scheduleCall(id, params) {
  return axios.post(
    `${apiUrl}leads/${id}/schedule_call`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function unsubscribeSms(params) {
  return axios.post(
    `${apiUrl}leads/unsubscribe_sms`,
    pickBy(params, v => v !== undefined && v !== null)
  )
}

export function quotaCalculator(id, params) {
  return axios.post(
    `${apiUrl}quota_calculator/${id}`,
    pickBy({ ...params, country_code: window.countryCode }, v => v !== undefined && v !== null)
  )
}

export function bearerToken() {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();

  const currentDateFormateada = `${day}-${month}-${year}`;
  const partialToken = 'desarrolladoEntreJavierOrtizYSalvadorVelázquez';
  const token = crypto.createHash('md5').update(currentDateFormateada + partialToken).digest('hex');

  return token;
}
